<template>
  <DashboardTemplate>
    <Layout class="main-slot">
      <h3 class="text-center mg-t-1">แก้ไขรหัสผ่าน</h3>
      <el-form
        :model="ruleForm"
        :rules="rules"
        label-position="top"
        ref="ruleForm"
        class="demo-ruleForm"
        @submit.prevent.native="submitForm('ruleForm')"
      >
        <el-form-item label="รหัสผ่านปัจจุบัน" prop="oldPassword">
          <el-input
            v-model="ruleForm.oldPassword"
            type="password"
            show-password
            maxlength="30"
            placeholder="กรุณากรอกรหัสผ่านอย่างน้อย 8 ตัวอักษร"
          ></el-input>
        </el-form-item>
        <el-form-item label="รหัสผ่านใหม่" prop="password">
          <el-input
            v-model="ruleForm.password"
            @input="validPass"
            type="password"
            show-password
            maxlength="30"
            placeholder="กรุณากรอกรหัสผ่านอย่างน้อย 8 ตัวอักษร"
          ></el-input>
        </el-form-item>
        <el-form-item label="ยืนยันรหัสผ่าน" prop="repassword">
          <el-input
            v-model="ruleForm.repassword"
            type="password"
            show-password
            maxlength="30"
            placeholder="กรุณากรอกรหัสผ่านอย่างน้อย 8 ตัวอักษร"
            @keyup.enter.native="submitForm('ruleForm')"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="is-flex js-between mg-t-4">
            <div
              @click="$router.go(-1)"
              class="text-grey-light hover-secondary cr-pointer"
            >
              ยกเลิก
            </div>
            <el-button
              type="primary"
              class="wid180"
              @click="submitForm('ruleForm')"
              :loading="loading"
              >บันทึก</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </Layout>
  </DashboardTemplate>
</template>
<script>
import DashboardTemplate from "@/template/DashboardTemplate";
import { HTTP } from "@/service/axios";

import Layout from "@/template/LayoutDashboardTemplate";
export default {
  computed: {
    token() {
      return this.$store.state.token;
    },
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    signout() {
      this.$confirm("แก้ไขรหัสผ่านใหม่สำเร็จ กรุณาเข้าระบบใหม่อีกครั้ง", {
        confirmButtonText: "OK",
        showCancelButton: false,
        type: "success",
      })
        .then(() => {
          setTimeout(() => {
            window.localStorage.eram.clear();
          }, 200);
          this.$store.commit("SIGNOUT");
          window.localStorage.removeItem("eram");
          delete HTTP.defaults.headers.common.Authorization;
          this.$router.push("/");
          setTimeout(() => {
            this.$router.go();
          }, 250);
        })
        .catch((action) => {
          setTimeout(() => {
            window.localStorage.eram.clear();
          }, 200);
          this.$store.commit("SIGNOUT");
          window.localStorage.removeItem("eram");
          delete HTTP.defaults.headers.common.Authorization;
          this.$router.push("/");
          setTimeout(() => {
            this.$router.go();
          }, 250);
        });
    },
    validPass() {
      let arr = ["..%u2216", "..%c0%af", "%2e%2e%2f", "..%255c", "../", "..\\"];
      let reg = new RegExp(
        /..%u2216|..%c0%af|..%c1%9c|%2e%2e%2f|%2e%2e\/|..%2f|%2e%2e\\|..%5c|%252e%252e%255c|..%255c|\.\.\/|\.\/|\.\.\\|\.\\|\.\.|%00|%0|%2|%5|%%|%c|%d|%e|%f|%p|%u|%x|%s|%n|%\d{1,2}!s|%\d{1,2}!n\w+/g,
        "gi"
      );
      let index = reg.test(this.ruleForm.password);
      let matches = this.ruleForm.password.match(reg);
      if (index) {
        if (matches.length != null) {
          matches.map((data) => {
            this.ruleForm.password = this.ruleForm.password.replace(data, "");
          });
        }
        this.$message.error("ห้ามใช้อักขระพิเศษ");
      }
    },
    submitForm(formName) {
      this.loading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let tempOldPassword = this.CryptoJS.AES.encrypt(
            this.ruleForm.oldPassword,
            this.sPhrase
          ).toString();

          let tempPassword = this.CryptoJS.AES.encrypt(
            this.ruleForm.password,
            this.sPhrase
          ).toString();

          let obj = {
            oldPassword: tempOldPassword,
            password: tempPassword,
          };

          //HTTP.defaults.headers.common['X-CSRF-TOKEN'] = this.$csrfToken
          HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
          HTTP.put("/account/reset/password", obj).then((res) => {
            //console.log("/account/reset/password", res);
            if (res.data.success) {
              this.signout();
            } else if (res.data.data == "invalid password.") {
              this.loading = false;
              this.$message.error(
                "รหัสผ่านปัจจุบันผิด กรุณากรอกรหัสปัจจุบันใหม่อีกครั้ง"
              );
            } else {
              this.loading = false;
              this.$message.error(res.data.data);
            }
          });
        } else {
          this.loading = false;
          console.log("error submit!!");
          return false;
        }
      });
    },
    async getSphase(){
      try {
        HTTP.defaults.headers.common.Authorization = `Bearer ${this.$store.state.sphrase}`;
        let res = await HTTP.get(`key/sign`)
        if(res.data.success){
          this.sPhrase = res.data.data
        }
      } catch (error) {
        
      }
    },
  },
  mounted(){
    this.getSphase()
  },
  components: {
    DashboardTemplate,
    Layout,
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("กรุณากรอกรหัสผ่าน"));
      } else {
        if (this.ruleForm.repassword !== "") {
          this.$refs.ruleForm.validateField("confirmPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("กรุณากรอกรหัสผ่านอีกครั้ง"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("รหัสผ่านไม่ตรงกัน"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      sPhrase: '',
      ruleForm: {
        oldPassword: "",
        password: "",
        repassword: "",
      },
      rules: {
        oldPassword: [
          { required: true, message: "กรุณากรอกรหัสผ่านเดิม", trigger: "blur" },
          { validator: validatePass, trigger: "blur" },
        ],
        password: [
          { required: true, message: "กรุณากรอกรหัสผ่าน", trigger: "blur" },
          { validator: validatePass, trigger: "blur" },
          {
            min: 8,
            message: "รหัสผ่านต้องมีความยาวไม่น้อยกว่า 8 ตัวอักษร",
            trigger: "blur",
          },
        ],
        repassword: [
          { required: true, message: "กรุณากรอกรหัสผ่าน", trigger: "blur" },
          { validator: validatePass2, trigger: "blur" },
          {
            min: 8,
            message: "รหัสผ่านต้องมีความยาวไม่น้อยกว่า 8 ตัวอักษร",
            trigger: "blur",
          },
        ],
      },
    };
  },
};
</script>
